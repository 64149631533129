/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background-color: #f6f6f6;
}

.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.lh100 { line-height: 100%; }
.lh110 { line-height: 110%; }
.lh130 { line-height: 130%; }
.lh140 { line-height: 140%; }
.lh150 { line-height: 150%; }
.lh160 { line-height: 160%; }
.lh170 { line-height: 170%; }
.lh180 { line-height: 180%; }
.lh190 { line-height: 190%; }
.lh200 { line-height: 200%; }

.bgptl { background-position: top left; }
.bgptc { background-position: top center; }
.bgptr { background-position: top right; }
.bgpcl { background-position: center left; }
.bgpcc { background-position: center center; }
.bgpcr { background-position: center right; }
.bgpbl { background-position: bottom left; }
.bgpbc { background-position: bottom center; }
.bgpbr { background-position: bottom right; }

.bgrr { background-repeat: repeat; }
.bgrnr { background-repeat: no-repeat; }
.bgrrx { background-repeat: repeat-x; }
.bgrry { background-repeat: repeat-y; }

.bgc0, .bgc0_h:hover { background-color: #000; }
.bgc0_i, .bgc0_h_i:hover { background-color: #000 !important; }
.bgc0_25p, .bgc0_25p_h:hover { background-color: rgba(0,0,0,.25); }
.bgc0_25p_i, .bgc0_25p_h_i:hover { background-color: rgba(0,0,0,.25) !important; }
.bgc0_50p, .bgc0_50p_h:hover { background-color: rgba(0,0,0,.5); }
.bgc0_50p_i, .bgc0_50p_h_i:hover { background-color: rgba(0,0,0,.5) !important; }
.bgc0_75p, .bgc0_75p_h:hover { background-color: rgba(0,0,0,.75); }
.bgc0_75p_i, .bgc0_75p_h_i:hover { background-color: rgba(0,0,0,.75) !important; }
.bgcw, .bgcw_h:hover { background-color: #fff; }
.bgcw_i, .bgcw_h_i:hover { background-color: #fff !important; }
.bgcw_25p, .bgcw_25p_h:hover { background-color: rgba(255,255,255,.25); }
.bgcw_25p_i, .bgcw_25p_h_i:hover { background-color: rgba(255,255,255,.25) !important; }
.bgcw_50p, .bgcw_50p_h:hover { background-color: rgba(255,255,255,.5); }
.bgcw_50p_i, .bgcw_50p_h_i:hover { background-color: rgba(255,255,255,.5) !important; }
.bgcw_75p, .bgcw_75p_h:hover { background-color: rgba(255,255,255,.75); }
.bgcw_75p_i, .bgcw_75p_h_i:hover { background-color: rgba(255,255,255,.75) !important; }
.bgcf6, .bgcf6_h:hover { background-color: #f6f6f6; }
.bgcf6_i, .bgcf6_h_i:hover { background-color: #f6f6f6 !important; }
.bgcea, .bgcea_h:hover { background-color: #eaeaea; }
.bgcea_i, .bgcea_h_i:hover { background-color: #eaeaea !important; }
.bgcc, .bgcc_h:hover { background-color: #ccc; }
.bgcc_i, .bgcc_h_i:hover { background-color: #ccc !important; }
 .bgcblue, .bgcblue_h:hover { background-color: #3278be; }
.bgcblue_i, .bgcblue_h_i:hover { background-color: #3278be !important; }
.bgcorange, .bgcorange_h:hover { background-color: #f0964b; }
.bgcorange_i, .bgcorange_h_i:hover { background-color: #f0964b !important; }

.c0, .c0_a, .c0_a *, .c0_h:hover, .c0_a_h:hover, .c0_a_h:hover * { color: #000; }
.c0_i, .c0_a_i, .c0_a_i *, .c0_h_i:hover, .c0_a_h_i:hover, .c0_a_h_i:hover * { color: #000 !important; }
.cw, .cw_a, .cw_a *, .cw_h:hover, .cw_a_h:hover, .cw_a_h:hover * { color: #fff; }
.cw_i, .cw_a_i, .cw_a_i *, .cw_h_i:hover, .cw_a_h_i:hover, .cw_a_h_i:hover * { color: #fff !important; }
.cc, .cc_h:hover { color: #ccc; }
.cc_i, .cc_h_i:hover { color: #ccc !important; }
.c90, .c90_h:hover { color: #909090; }
.c90_i, .c90_h_i:hover { color: #909090 !important; }
.c50, .c50_h:hover { color: #505050; }
.c50_i, .c50_h_i:hover { color: #505050 !important; }
label .required { color: #f33c38; }
.cblue, .cblue_h:hover { color: #3278be; }
.cblue_i, .cblue_h_i:hover { color: #3278be !important; }
.corange, .corange_h:hover { color: #f0964b; }
.corange_i, .corange_h_i:hover { color: #f0964b !important; }

.brct, .brct_h:hover { border-color: transparent; }
.brct_i, .brct_h_i:hover { border-color: transparent !important; }
.brc0, .brc0_h:hover { border-color: #000; }
.brc0_i, .brc0_h_i:hover { border-color: #000 !important; }
.brcw, .brcw_h:hover { border-color: #fff; }
.brcw_i, .brcw_h_i:hover { border-color: #fff !important; }
.brcf6, .brcf6_h:hover { border-color: #f6f6f6; }
.brcf6_i, .brcf6_h_i:hover { border-color: #f6f6f6 !important; }
.brcea, .brcea_h:hover { border-color: #eaeaea; }
.brcea_i, .brcea_h_i:hover { border-color: #eaeaea !important; }
.brcc, .brcc_h:hover { border-color: #ccc; }
.brcc_i, .brcc_h_i:hover { border-color: #ccc !important; }
.brc90, .brc90_h:hover { border-color: #909090; }
.brc90_i, .brc90_h_i:hover { border-color: #909090 !important; }
.brc50, .brc50_h:hover { border-color: #505050; }
.brc50_i, .brc50_h_i:hover { border-color: #505050 !important; }
.brcblue, .brcblue_h:hover { border-color: #3278be; }
.brcblue_i, .brcblue_h_i:hover { border-color: #3278be !important; }
.brcorange, .brcorange_h:hover { border-color: #f0964b; }
.brcorange_i, .brcorange_h_i:hover { border-color: #f0964b !important; }

.brw1 { border-width: 1px; }
.brtw1 { border-top-width: 1px; }
.brlw1 { border-left-width: 1px; }
.brrw1 { border-right-width: 1px; }
.brbw1 { border-bottom-width: 1px; }
.brw2 { border-width: 2px; }
.brtw2 { border-top-width: 2px; }
.brlw2 { border-left-width: 2px; }
.brrw2 { border-right-width: 2px; }
.brbw2 { border-bottom-width: 2px; }
.brw3 { border-width: 3px; }
.brtw3 { border-top-width: 3px; }
.brlw3 { border-left-width: 3px; }
.brrw3 { border-right-width: 3px; }
.brbw3 { border-bottom-width: 3px; }
.brw5 { border-width: 5px; }
.brtw5 { border-top-width: 5px; }
.brlw5 { border-left-width: 5px; }
.brrw5 { border-right-width: 5px; }
.brbw5 { border-bottom-width: 5px; }
.brw7 { border-width: 7px; }
.brtw7 { border-top-width: 7px; }
.brlw7 { border-left-width: 7px; }
.brrw7 { border-right-width: 7px; }
.brbw7 { border-bottom-width: 7px; }
.brw10 { border-width: 10px; }
.brtw10 { border-top-width: 10px; }
.brlw10 { border-left-width: 10px; }
.brrw10 { border-right-width: 10px; }
.brbw10 { border-bottom-width: 10px; }

.brstn { border-style: none; }
.brsts { border-style: solid; }
.brstd { border-style: dashed; }

.brs2 { border-radius: 2px; }
.brst2 { border-radius: 2px 2px 0px 0px; }
.brsl2 { border-radius: 2px 0px 0px 2px; }
.brsr2 { border-radius: 0px 2px 2px 0px; }
.brsb2 { border-radius: 0px 0px 2px 2px; }
.brs3 { border-radius: 3px; }
.brst3 { border-radius: 3px 3px 0px 0px; }
.brsl3 { border-radius: 3px 0px 0px 3px; }
.brsr3 { border-radius: 0px 3px 3px 0px; }
.brsb3 { border-radius: 0px 0px 3px 3px; }
.brs4 { border-radius: 4px; }
.brst4 { border-radius: 4px 4px 0px 0px; }
.brsl4 { border-radius: 4px 0px 0px 4px; }
.brsr4 { border-radius: 0px 4px 4px 0px; }
.brsb4 { border-radius: 0px 0px 4px 4px; }
.brs5 { border-radius: 5px; }
.brst5 { border-radius: 5px 5px 0px 0px; }
.brsl5 { border-radius: 5px 0px 0px 5px; }
.brsr5 { border-radius: 0px 5px 5px 0px; }
.brsb5 { border-radius: 0px 0px 5px 5px; }
.brs7 { border-radius: 7px; }
.brst7 { border-radius: 7px 7px 0px 0px; }
.brsl7 { border-radius: 7px 0px 0px 7px; }
.brsr7 { border-radius: 0px 7px 7px 0px; }
.brsb7 { border-radius: 0px 0px 7px 7px; }
.brs10 { border-radius: 10px; }
.brst10 { border-radius: 10px 10px 0px 0px; }
.brsl10 { border-radius: 10px 0px 0px 10px; }
.brsr10 { border-radius: 0px 10px 10px 0px; }
.brsb10 { border-radius: 0px 0px 10px 10px; }
.brs50p { border-radius: 50%; }

.dn { display: none; }
.dn_i { display: none !important; }
.db { display: block; }
.dib { display: inline-block; }
.dt { display: table; }
.dtr { display: table-row; }
.dtc { display: table-cell; }
/* Flex */
.dfdr { display: flex; flex-direction: row; }
.dfdrr { display: flex; flex-direction: row-reverse; }
.dfdc { display: flex; flex-direction: column; }
.dfdcr { display: flex; flex-direction: column-reverse; }
.fwn { flex-wrap: nowrap; }
.fww { flex-wrap: wrap; }
.fwwr { flex-wrap: wrap-reverse; }
.fjcs { justify-content: flex-start; }
.fjce { justify-content: flex-end; }
.fjcc { justify-content: center; }
.fjcsa { justify-content: space-around; }
.fjcsb { justify-content: space-between; }
.fais { align-items: flex-start; }
.faie { align-items: flex-end; }
.faic { align-items: center; }
.faib { align-items: baseline; }
.faist { align-items: stretch; }

.curp, .curp_a, .curp_a * { cursor: pointer; }
.curp_i, .curp_a_i, .curp_a_i * { cursor: pointer !important; }
.curd, .curd_a, .curd_a * { cursor: default; }
.curd_i, .curd_a_i, .curd_a_i * { cursor: default !important; }

.h0 { height: 0px; }
.hfull { height: 100%; }
.hauto { height: auto; }

.w0 { width: 0px; }
.w1 { width: 1px; }
.w200 { width: 200px; }
.w230 { width: 230px; }
.w260 { width: 260px; }
.w280 { width: 280px; }
.w320 { width: 320px; }
.w350 { width: 350px; }
.w400 { width: 400px; }
.w480 { width: 480px; }
.w570 { width: 570px; }
.w640 { width: 640px; }
.w700 { width: 700px; }
.w750 { width: 750px; }
.w800 { width: 800px; }
.w860 { width: 860px; }
.w960 { width: 960px; }
.w1024 { width: 1024px; }
.w1048 { width: 1048px; }
.w1100 { width: 1100px; }
.w1200 { width: 1200px; }
.w10p { width: 10%; }
.w12p { width: 12.5%; }
.w15p { width: 15%; }
.w20p { width: 20%; }
.w25p { width: 25%; }
.w33p { width: 33.3333%; }
.w50p { width: 50%; }
.w65p { width: 65%; }
.w66p { width: 66.6666%; }
.w70p { width: 70%; }
.w75p { width: 75%; }
.wfull { width: 100%; }
.wauto { width: auto; }

.m0a { margin: 0 auto; }
.m0a_i { margin: 0 auto !important; }
.ma0 { margin: auto 0; }
.ma0_i { margin: auto 0 !important; }
.ma { margin: auto; }
.ma_i { margin: auto !important; }
.mta { margin-top: auto; }
.mta_i { margin-top: auto !important; }
.mla { margin-left: auto; }
.mla_i { margin-left: auto !important; }
.mra { margin-right: auto; }
.mra_i { margin-right: auto !important; }
.mba { margin-bottom: auto; }
.mba_i { margin-bottom: auto !important; }
.m0 { margin: 0; }
.m0_i { margin: 0 !important; }
.mt0 { margin-top: 0; }
.mt0_i { margin-top: 0 !important; }
.ml0 { margin-left: 0; }
.ml0_i { margin-left: 0 !important; }
.mr0 { margin-right: 0; }
.mr0_i { margin-right: 0 !important; }
.mb0 { margin-bottom: 0; }
.mb0_i { margin-bottom: 0 !important; }
.m1 { margin: 1px; }
.mt1 { margin-top: 1px; }
.ml1 { margin-left: 1px; }
.mr1 { margin-right: 1px; }
.mb1 { margin-bottom: 1px; }
.m2 { margin: 2px; }
.mt2 { margin-top: 2px; }
.ml2 { margin-left: 2px; }
.mr2 { margin-right: 2px; }
.mb2 { margin-bottom: 2px; }
.m3 { margin: 3px; }
.mt3 { margin-top: 3px; }
.ml3 { margin-left: 3px; }
.mr3 { margin-right: 3px; }
.mb3 { margin-bottom: 3px; }
.m4 { margin: 4px; }
.mt4 { margin-top: 4px; }
.ml4 { margin-left: 4px; }
.mr4 { margin-right: 4px; }
.mb4 { margin-bottom: 4px; }
.m5 { margin: 5px; }
.mt5 { margin-top: 5px; }
.ml5 { margin-left: 5px; }
.mr5 { margin-right: 5px; }
.mb5 { margin-bottom: 5px; }
.m7 { margin: 7px; }
.mt7 { margin-top: 7px; }
.ml7 { margin-left: 7px; }
.mr7 { margin-right: 7px; }
.mb7 { margin-bottom: 7px; }
.m10 { margin: 10px; }
.mt10 { margin-top: 10px; }
.ml10 { margin-left: 10px; }
.mr10 { margin-right: 10px; }
.mb10 { margin-bottom: 10px; }
.m13 { margin: 13px; }
.mt13 { margin-top: 13px; }
.ml13 { margin-left: 13px; }
.mr13 { margin-right: 13px; }
.mb13 { margin-bottom: 13px; }
.m15 { margin: 15px; }
.mt15 { margin-top: 15px; }
.ml15 { margin-left: 15px; }
.mr15 { margin-right: 15px; }
.mb15 { margin-bottom: 15px; }
.m20 { margin: 20px; }
.mt20 { margin-top: 20px; }
.ml20 { margin-left: 20px; }
.mr20 { margin-right: 20px; }
.mb20 { margin-bottom: 20px; }
.m-1 { margin: -1px; }
.mt-1 { margin-top: -1px; }
.ml-1 { margin-left: -1px; }
.mr-1 { margin-right: -1px; }
.mb-1 { margin-bottom: -1px; }
.m-2 { margin: -2px; }
.mt-2 { margin-top: -2px; }
.ml-2 { margin-left: -2px; }
.mr-2 { margin-right: -2px; }
.mb-2 { margin-bottom: -2px; }
.m-3 { margin: -3px; }
.mt-3 { margin-top: -3px; }
.ml-3 { margin-left: -3px; }
.mr-3 { margin-right: -3px; }
.mb-3 { margin-bottom: -3px; }
.m-4 { margin: -4px; }
.mt-4 { margin-top: -4px; }
.ml-4 { margin-left: -4px; }
.mr-4 { margin-right: -4px; }
.mb-4 { margin-bottom: -4px; }
.m-5 { margin: -5px; }
.mt-5 { margin-top: -5px; }
.ml-5 { margin-left: -5px; }
.mr-5 { margin-right: -5px; }
.mb-5 { margin-bottom: -5px; }
.m-7 { margin: -7px; }
.mt-7 { margin-top: -7px; }
.ml-7 { margin-left: -7px; }
.mr-7 { margin-right: -7px; }
.mb-7 { margin-bottom: -7px; }
.m-10 { margin: -10px; }
.mt-10 { margin-top: -10px; }
.ml-10 { margin-left: -10px; }
.mr-10 { margin-right: -10px; }
.mb-10 { margin-bottom: -10px; }
.m-13 { margin: -13px; }
.mt-13 { margin-top: -13px; }
.ml-13 { margin-left: -13px; }
.mr-13 { margin-right: -13px; }
.mb-13 { margin-bottom: -13px; }
.m-15 { margin: -15px; }
.mt-15 { margin-top: -15px; }
.ml-15 { margin-left: -15px; }
.mr-15 { margin-right: -15px; }
.mb-15 { margin-bottom: -15px; }
.m-20 { margin: -20px; }
.mt-20 { margin-top: -20px; }
.ml-20 { margin-left: -20px; }
.mr-20 { margin-right: -20px; }
.mb-20 { margin-bottom: -20px; }

.p0 { padding: 0 !important; }
.pt0 { padding-top: 0 !important; }
.pl0 { padding-left: 0 !important; }
.pr0 { padding-right: 0 !important; }
.pb0 { padding-bottom: 0 !important; }
.p1 { padding: 1px !important; }
.pt1 { padding-top: 1px !important; }
.pl1 { padding-left: 1px !important; }
.pr1 { padding-right: 1px !important; }
.pb1 { padding-bottom: 1px !important; }
.p2 { padding: 2px !important; }
.pt2 { padding-top: 2px !important; }
.pl2 { padding-left: 2px !important; }
.pr2 { padding-right: 2px !important; }
.pb2 { padding-bottom: 2px !important; }
.p3 { padding: 3px !important; }
.pt3 { padding-top: 3px !important; }
.pl3 { padding-left: 3px !important; }
.pr3 { padding-right: 3px !important; }
.pb3 { padding-bottom: 3px !important; }
.p4 { padding: 4px !important; }
.pt4 { padding-top: 4px !important; }
.pl4 { padding-left: 4px !important; }
.pr4 { padding-right: 4px !important; }
.pb4 { padding-bottom: 4px !important; }
.p5 { padding: 5px !important; }
.pt5 { padding-top: 5px !important; }
.pl5 { padding-left: 5px !important; }
.pr5 { padding-right: 5px !important; }
.pb5 { padding-bottom: 5px !important; }
.p7 { padding: 7px !important; }
.pt7 { padding-top: 7px !important; }
.pl7 { padding-left: 7px !important; }
.pr7 { padding-right: 7px !important; }
.pb7 { padding-bottom: 7px !important; }
.p10 { padding: 10px !important; }
.pt10 { padding-top: 10px !important; }
.pl10 { padding-left: 10px !important; }
.pr10 { padding-right: 10px !important; }
.pb10 { padding-bottom: 10px !important; }
.p13 { padding: 13px !important; }
.pt13 { padding-top: 13px !important; }
.pl13 { padding-left: 13px !important; }
.pr13 { padding-right: 13px !important; }
.pb13 { padding-bottom: 13px !important; }
.p15 { padding: 15px !important; }
.pt15 { padding-top: 15px !important; }
.pl15 { padding-left: 15px !important; }
.pr15 { padding-right: 15px !important; }
.pb15 { padding-bottom: 15px !important; }
.p20 { padding: 20px !important; }
.pt20 { padding-top: 20px !important; }
.pl20 { padding-left: 20px !important; }
.pr20 { padding-right: 20px !important; }
.pb20 { padding-bottom: 20px !important; }

.fw900, .fw900 *, .black, .black * { font-weight: 900; }
.fw700, .fw700 *, .bold, .bold * { font-weight: 700; }
.fw600, .fw600 *, .semibold, .semibold * { font-weight: 600; }
.fw400, .fw400 * { font-weight: 400; }
.fw300, .fw300 *, .semilight, .semilight * { font-weight: 300; }

.tal { text-align: left; }
.tal_i { text-align: left !important; }
.tac { text-align: center; }
.tac_i { text-align: center !important; }
.tar { text-align: right; }
.tar_i { text-align: right !important; }
.taj { text-align: justify; }
.taj_i { text-align: justify !important; }

.tdn, .tdn_a, .tdn_a *, .tdn_h:hover, .tdn_a_h:hover, .tdn_a_h:hover * { text-decoration: none; }
.tdn_i, .tdn_a_i, .tdn_a_i *, .tdn_h_i:hover, .tdn_a_h_i:hover, .tdn_a_h_i:hover * { text-decoration: none !important; }
.tdu, .tdu_a, .tdu_a *, .tdu_h:hover, .tdu_a_h:hover, .tdu_a_h:hover * { text-decoration: underline; }
.tdu_i, .tdu_a_i, .tdu_a_i *, .tdu_h_i:hover, .tdu_a_h_i:hover, .tdu_a_h_i:hover * { text-decoration: underline !important; }
.tdlth, .tdlth_a, .tdlth_a *, .tdlth_h:hover, .tdlth_a_h:hover, .tdlth_a_h:hover * { text-decoration: line-through; }
.tdlth_i, .tdlth_a_i, .tdlth_a_i *, .tdlth_h_i:hover, .tdlth_a_h_i:hover, .tdlth_a_h_i:hover * { text-decoration: line-through !important; }

.vat { vertical-align: top; }
.vam { vertical-align: middle; }
.vab { vertical-align: bottom; }
.vabl { vertical-align: baseline; }

.fz0 { font-size: 0; }
.fz0_i { font-size: 0 !important; }
.fz10, .fz10_a, .fz10_a * { font-size: 10px; }
.fz11, .fz11_a, .fz11_a * { font-size: 11px; }
.fz12, .fz12_a, .fz12_a * { font-size: 12px; }
.fz14, .fz14_a, .fz14_a * { font-size: 14px; }
.fz15 { font-size: 15px; }
.fz16 { font-size: 16px; }
.fz18 { font-size: 18px; }
.fz20 { font-size: 20px; }
.fz22 { font-size: 22px; }
.fz24 { font-size: 24px; }
.fz26 { font-size: 26px; }
.fz28 { font-size: 28px; }
.fz30 { font-size: 30px; }
.fz32 { font-size: 32px; }
.fz34 { font-size: 34px; }
.fz36 { font-size: 36px; }
.fz46 { font-size: 46px; }
.fz48 { font-size: 48px; }
.fz50 { font-size: 50px; }
.fz52 { font-size: 52px; }
.fz54 { font-size: 54px; }
.fsi { font-style: italic; }

.z0 { z-index: 0; }
.z4 { z-index: 4; }
.z9 { z-index: 9; }
.z99 { z-index: 99; }
.z999 { z-index: 999; }
.z9999 { z-index: 9999; }

.caps { text-transform: uppercase; }

.ofh { overflow: hidden; }

.inherit { font-family: inherit; font-size: inherit; font-weight: inherit; font-style: inherit; text-transform: inherit; }
.fzinherit { font-size: inherit; }

.posr { position: relative; }
.posa { position: absolute; }
.posf { position: fixed; }

.t0 { top: 0px; }
.t100p { top: 100%; }
.t-100p { top: -100%; }
.l0 { left: 0px; }
.l100p { left: 100%; }
.l-100p { left: -100%; }
.r0 { right: 0px; }
.r100p { right: 100%; }
.r-100p { right: -100%; }
.b0 { bottom: 0px; }
.b100p { bottom: 100%; }
.b-100p { bottom: -100%; }

.fll { float: left; }
.flr { float: right; }

p { margin-bottom: 20px; text-align: justify; }
p + p { margin-top: -8px; }

.link {
  color: #3278be;
  text-decoration: underline;
  cursor: pointer;
}
  .link:hover {
    text-decoration: none;
  }

a[href^="tel:"],
a[href^="mailto:"] {
  white-space: nowrap;
}


.form {
  position: relative;
}
  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
    .form-row + .form-row,
    .form-buttons {
      margin-top: 20px;
    }
      .form-label {
        width: 165px;
        min-width: 165px;
        margin-top: 11px;
      }
        .form-label span.required {
          font-weight: 700;
        }

      .form-field {
        width: 100%;
      }
        .input-container {
          background-color: #fff;
          border: 2px solid #e1e1e1;
          padding: 8px 12px 7px;
          position: relative;
          /*font-size: 0px;*/
          z-index: 1;
        }
          .input-container input,
          .input-container textarea,
          .input-container select {
            background: transparent;
            border: 0;
            width: 100%;
            min-width: 100%;
          }

        .form-error {
          position: relative;
        }
          .errorMessage {
            background-color: #f0964b;
            padding: 3px 6px 2px;
            margin: 0px;
            color: #fff;
            font-size: 13px;
            font-weight: 400;
            line-height: 1.3;
          }

  .form-button {
    display: inline-block;
  }


.button-style {
  background: none;
  display: inline-block;
  border: 0.1875em solid #f0964b;
  font-size: 1rem;
  line-height: 0;
  text-align: center;
  padding: 0px;
  cursor: pointer;
  position: relative;
}
  .button-style:hover,
  .button-style.active {
    background: #f0964b
  }

  .button-style-blue {
    border-color: #3278be;
  }
    .button-style-blue:hover,
    .button-style-blue.active {
      background: #3278be;
    }

  .button-style-white {
    border-color: #fff;
  }
    .button-style-white:hover,
    .button-style-white.active {
      background: #fff;
    }

  .button-style-black {
    border-color: #000;
  }
    .button-style-black:hover,
    .button-style-black.active {
      background: #000;
    }

  .button-style > * {
    display: inline-block;
    color: #f0964b;
    line-height: 1.3;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    padding: 0.1875em 1.5em;
    margin: 0.4375em 0 0.375em;
    position: relative;
    z-index: 1;
  }
    .button-style.button-style-blue > * {
      color: #3278be;
    }
    .button-style.button-style-black > * {
      color: #000;
    }

    .button-style:hover > *,
    .button-style.active > * {
      color: #fff;
    }
      .button-style.button-style-white:hover > *,
      .button-style.button-style-white.active > * {
        color: #f0964b;
      }


ul.ul, ol.ol {
  margin-top: -6px;
  margin-bottom: 20px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 20px;
    margin-top: 0px;
    margin-bottom: 2px;
  }
  ul.ul li, ol.ol li {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    padding-top: 6px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li:before {
      content: "●";
      display: inline-block;
      background: url('/i/ul-list-style-image.svg') no-repeat 50% 50% transparent;
      background-size: contain;
      font-weight: 600;
      margin-right: 0.6em;
      width: 0.62em;
      color: transparent;
    }
    ol.ol li:before {
      content: counters(ol-list-counter, ".") ". ";
      counter-increment: ol-list-counter;
      font-weight: 600;
      margin-right: 0.6em;
      color: #3278be;
    }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:before {
    content: "";
    background: url('/i/gallery-image-zoom.svg') 100% 100% no-repeat transparent;
    background-size: contain;
    position: absolute;
    right: 15px;
    bottom: 15px;
    width: 8%;
    height: 8%;
    opacity: 0.7;

    -webkit-transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
    -moz-transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
    -o-transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
    transition: width 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s;
  }
    a.zoom-image:hover:before {
      width: 10%;
      height: 10%;
      opacity: 1 !important;
    }


.wrapper {
  width: 1204px;
  position: relative;
  margin: 0px auto;
  padding: 0px;
}
  .unwrapper {
    margin-left: -9999px;
    margin-right: -9999px;
    position: static;
  }



/* Header */
.header {
  position: relative;
  z-index: 5;
}
  .header-info-menu-container ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
    .header-info-menu-container ul li {
      position: relative;
    }
      .header-info-menu-container ul li + li:before {
        content: "";
        position: absolute;
        background: #000;
        top: 5px;
        left: 0px;
        bottom: 5px;
        width: 1px;
      }
      .header-info-menu-container ul li a {
        display: inline-block;
        color: #3278be;
        font-size: 15px;
        font-weight: 400;
        padding: 5px 14px 6px;
        cursor: pointer;
      }
        .header-info-menu-container ul li a:hover,
        .header-info-menu-container ul li.active a {
          text-decoration: underline;
        }
        .header-info-menu-container ul li.active a {
          color: #f0964b;
          font-weight: 600;
          cursor: default;
        }


  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px 15px;
  }
    .header-logo-container > a {
      cursor: pointer;
      text-decoration: none;
    }
    .header-logo-container div {
      font-size: 0px;
    }
    /*.header-logo-container > a > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }*/
      /*.header-logo-container img {
        width: 70px;
        !*margin-right: -12px;*!
      }*/
      /*.header-logo-container span {
        display: block;
        text-align: right;
        line-height: 1;
        letter-spacing: -0.04em;
      }
        .header-logo-container span.first {
          font-size: 24px;
          font-weight: 700;
          text-transform: uppercase;
          padding-right: 5px;
        }
        .header-logo-container span.last {
          color: #3278be;
          font-size: 42px;
          font-weight: 600;
          margin-top: -14px;
        }
          .header-logo-container span.last:first-letter {
            font-size: 60px;
          }*/


    .header-phones-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
      .header-phones {
        padding-bottom: 10px;
      }
        .header-phones div {
          font-size: 0px;
        }
          .header-phones a {
            font-size: 35px;
            font-weight: 600;
            line-height: 1;
            text-decoration: none;
            cursor: default;
          }

      .header-worktime * {
        font-size: 16px;
        line-height: 1.3;
      }
        .header-worktime * > span {
          display: inline-block;
          color: #3278be;
          font-weight: 700;
          padding: 0px 0px 0px 6px;
          margin: 0px 0px 0px 3px;
          position: relative;
        }
          .header-worktime * > span:before {
            content: "";
            position: absolute;
            background: #3278be;
            top: 3px;
            left: 0px;
            bottom: 2px;
            width: 2px;
          }


    .header-address * {
      font-size: 18px;
    }


    .header-content > div > div > .button-style {
      width: 100%;
    }
    .header-content > div > div + div > .button-style {
      margin-top: 5px;
    }
      .header-content .button-style > div {
        font-size: 0.875rem;
      }
    /*.header-content .callback-link {
      display: block;
      font-size: 0px;
      margin-right: 15px;
      cursor: pointer;
    }
      .header-content .callback-link:before {
        content: "";
        display: inline-block;
        background: url('/i/callback-button-icon--orange.svg') no-repeat left center transparent;
        background-size: auto 26px;
        width: 36px;
        height: 26px;
        vertical-align: middle;
        cursor: pointer;
      }
      .header-content .callback-link > * {
        display: inline-block;
        color: #f0964b;
        font-size: 1rem;
        line-height: 1.1;
        font-weight: 600;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        border-bottom: 1px dashed #f0964b;
      }
        .header-content .callback-link:hover > * {
          border-bottom-color: transparent;
        }*/


    .callback-button-style > * {
      padding-left: 3.5em;
      background-image: url('/i/callback-button-icon--orange.svg');
      background-repeat: no-repeat;
      background-position: 1.1em center;
      background-size: auto 26px;
    }
      .callback-button-style.active > *,
      .callback-button-style:hover > * {
        background-image: url('/i/callback-button-icon--white.svg');
      }


  .header-service-menu-container {
    background: #eaeaea;
    padding: 0px;
  }
    .header-service-menu-container > ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
      .header-service-menu-container > ul:first-child {
        background: #3278be;
        z-index: 1;
      }
      .header-service-menu-container > ul > li {
        position: relative;
      }
        .header-service-menu-container > ul > li > div > div > * {
          display: block;
          color: #000;
          font-size: 15px;
          font-weight: 700;
          text-transform: uppercase;
          white-space: nowrap;
          padding: 7px 18px;
          text-align: center;
        }
          .header-service-menu-container > ul:first-child > li > div > div > * {
            color: #fff;
            /*font-size: 17px;*/
            padding: 12px 18px;
          }
        .header-service-menu-container > ul li > div a {
          cursor: pointer;
        }
        .header-service-menu-container > ul li > div span,
        .header-service-menu-container > ul li.active > div > div > * {
          cursor: default;
        }
          /*.header-service-menu-container > ul > li.with-dropdown > div > div > * {
            background: rgba(2,100,165,.4);
          }
            .header-service-menu-container > ul > li.with-dropdown ~ li.with-dropdown > div > div > * {
              background: transparent;
            }*/
          .header-service-menu-container > ul > li > div > div:hover > *,
          .header-service-menu-container > ul > li.active > div > div > * {
            background: #333 !important;
            color: #fff;
          }

        .header-service-menu-container > ul > li > ul {
          background: #fff;
          position: absolute;
          top: 100%;
          left: 0px;
          min-width: 100%;
          /*font-size: 0px;*/
          padding: 18px 20px;
          border: 3px solid #333;

          visibility: hidden;
          opacity: 0;
        }
          .header-service-menu-container > ul > li > div:hover + ul,
          .header-service-menu-container > ul > li:hover > ul,
          .header-service-menu-container > ul > li.is-hovered > ul {
            visibility: visible;
            opacity: 1;
          }
          .header-service-menu-container > ul > li > ul li > div > div > * {
            display: block;
            padding: 2px 0px;
            white-space: nowrap;
          }
            .header-service-menu-container > ul > li > ul li > div > div > a:hover,
            .header-service-menu-container > ul > li > ul li.active > div > div > a {
              color: #3278be;
              text-decoration: underline;
            }
            .header-service-menu-container > ul > li > ul li.active > div > div > a {
              font-weight: 600;
              cursor: default;
            }

      /*.header-service-menu-container > ul > li > ul,
      .header-service-menu-container > ul > li.with-dropdown ~ li.with-dropdown > ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        background: rgba(2,100,165,.4);
        position: absolute;
        font-size: 0px;
        top: 100%;
        left: 0px;
        right: 0px;
        height: 42px;

        visibility: hidden;
        opacity: 0;
      }
        .header-service-menu-container > ul > li.with-dropdown > ul {
          visibility: visible;
          opacity: 1;
        }
        .header-service-menu-container > ul > li > ul > li {
          position: relative;
        }
          .header-service-menu-container > ul > li > ul > li > div > div > * {
            display: block;
            color: #000;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            white-space: nowrap;
            padding: 9px 18px;
            text-align: center;
          }
            .header-service-menu-container > ul > li > ul > li:hover > div > div > *,
            .header-service-menu-container > ul > li > ul > li.active > div > div > a {
              background: #333;
              color: #fff;
            }
            .header-service-menu-container > ul > li > ul > li.with-active > div > div > * {
              background: rgba(51,51,51,.6);
              color: #fff;
            }

          .header-service-menu-container > ul > li > ul > li > ul {
            background: #fff;
            position: absolute;
            top: 100%;
            left: 0px;
            min-width: 100%;
            font-size: 0px;
            padding: 18px 20px;
            border: 3px solid #333;

            visibility: hidden;
            opacity: 0;
          }
            .header-service-menu-container > ul > li > ul > li > div:hover + ul,
            .header-service-menu-container > ul > li > ul > li:hover > ul,
            .header-service-menu-container > ul > li > ul > li.is-hovered > ul {
              visibility: visible;
              opacity: 1;
            }
            .header-service-menu-container > ul > li > ul > li > ul li > div > div > * {
              display: block;
              padding: 2px 0px;
              white-space: nowrap;
            }
              .header-service-menu-container > ul > li > ul > li > ul li > div > div > a:hover,
              .header-service-menu-container > ul > li > ul > li > ul li.active > div > div > a {
                color: #3278be;
                text-decoration: underline;
              }
              .header-service-menu-container > ul > li > ul > li > ul li.active > div > div > a {
                font-weight: 600;
                cursor: default;
              }*/



/* Content block */
.content {
  padding: 0px 0px 10px;
  position: relative;
  z-index: 3;

  /*min-height: 3000px;*/
}
  .main-rotator-block-container {
    padding: 0px 0px 28px;
    margin: 0px 0px -28px;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
    /*.main-rotator-info-container {
      width: 1204px;
      height: 0px;
      margin: 0px auto;
      position: relative;
      z-index: 2;
    }
      .main-rotator-info-container > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;

        position: absolute;
        top: 0px;
        right: 0px;
        width: 365px;
        height: 400px;
        padding: 0px 20px;
      }
        .main-rotator-info-container > div:before {
          content: "";
          background: rgba(0,0,0,.85);
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          width: 9999px;
        }
        .main-rotator-info-container > div > * {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          height: 100%;
          padding: 0px;
          margin: 0px;
          position: relative;
        }
          .main-rotator-info-container > div > * + * {
            border-top: 1px solid #fff;
          }
          .main-rotator-info-container > div a {
            display: inline-block;
            font-size: 0px;
            padding: 10px 20px;
            cursor: default;
          }
            .main-rotator-info-container > div a[href],
            .main-rotator-info-container > div a[href] *,
            .main-rotator-info-container > div a[data-fancybox],
            .main-rotator-info-container > div a[data-fancybox] * {
              cursor: pointer;
            }
            .main-rotator-info-container > div a img {
              width: 40px;
              vertical-align: middle;
              margin-right: 20px;
            }
            .main-rotator-info-container > div a span {
              display: inline-block;
              color: #f0964b;
              font-size: 25px;
              line-height: 0.98;
              vertical-align: middle;
              text-decoration: none;
            }
              .main-rotator-info-container > div a[href] span,
              .main-rotator-info-container > div a[data-fancybox] span {
                border-bottom: 1px solid #f0964b;
              }
              .main-rotator-info-container > div a[data-fancybox] span {
                border-bottom-style: dashed;
              }
                .main-rotator-info-container > div a[href]:hover span,
                .main-rotator-info-container > div a[data-fancybox]:hover span {
                  border-bottom-color: transparent;
                }*/

    .main-rotator-container {
      /*height: 450px;*/
      margin-bottom: -28px;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
      .main-rotator-slide-container {
        padding-bottom: 28px;
        position: relative;
      }
        .main-rotator-slide-image {
          position: relative;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-color: #eaeaea;
          background-size: cover;
          height: 0px;
          padding-top: 45%;
        }
          .main-rotator-slide-image a {
            content: "";
            display: block;
            font-size: 0px;
            cursor: pointer;
            height: 100%;
          }
        .main-slide-code-container {
          background: #3278be;
          display: inline-block;
          max-width: 500px;
          margin: 0px auto;
          position: absolute;
          left: 10px;
          bottom: 0px;
        }
          .main-slide-code-container > div {
            margin: 15px 20px;
          }

      #main-rotator-controls {
        height: 0px;
      }
        #main-rotator-controls > div {
          height: 0px;
          padding-top: 45%;
        }
          #main-rotator-controls .slick-prev,
          #main-rotator-controls .slick-next {
            display: inline-block;
            font-size: 0px;
            top: 50%;
            padding: 10px;
            position: absolute;
            cursor: pointer;
            z-index: 1;

            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);
          }
            #main-rotator-controls .slick-prev:after,
            #main-rotator-controls .slick-next:after {
              content: "";
              display: block;
              background: no-repeat transparent;
              background-size: cover;
              width: 43px;
              height: 30px;
            }

            #main-rotator-controls .slick-prev {
              left: 0px;
            }
              #main-rotator-controls .slick-prev:after {
                background-image: url('/i/arrows--white.svg');
                background-position: left center;
              }
            #main-rotator-controls .slick-next {
              right: 0px;
            }
              #main-rotator-controls .slick-next:after {
                background-image: url('/i/arrows--white.svg');
                background-position: right center;
              }

      /*#main-rotator-dots {
        width: 1204px;
        height: 0px;
        margin: 0px auto;
        position: relative;
        z-index: 1;
      }
        #main-rotator-dots > div {
          float: right;
          width: 704px;
          height: 0px;
          padding-left: 34px;
          position: relative;
        }
          #main-rotator-dots > div > div {
            display: inline-block;
            min-width: 135px;
            margin-top: -32px;
          }
            #main-rotator-dots ul {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
              #main-rotator-dots li {
                width: 15px;
                height: 15px;
                border: 2px solid #b7b7b7;
                !*border-radius: 50%;*!
                cursor: pointer;
              }
                #main-rotator-dots li:hover {
                  border-color: #000;
                }
                #main-rotator-dots li + li {
                  margin-left: 15px;
                }
                #main-rotator-dots li.slick-active {
                  background: #3278be;
                  border-color: #3278be !important;
                }*/


  .content-bg {
    padding-top: 12px;
    overflow: hidden;
    position: relative;
  }
    .main-rotator-block-container + .content-bg {
      padding-top: 65px;
    }

    .breadcrumbs {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      padding: 15px 0px 0px;
      font-size: 0px;
    }
      .content-bg .breadcrumbs {
        padding: 3px;
      }
      .breadcrumbs li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 0px;
      }
        .breadcrumbs li:before,
        .breadcrumbs a,
        .breadcrumbs span {
          font-size: 13px;
          vertical-align: baseline;
          line-height: 1.1;
        }
        .breadcrumbs li + li:before {
          content: "";
          align-self: stretch;
          background: url('/i/breadcrumbs-arrow.svg') no-repeat 50% 50% transparent;
          background-size: auto 70%;
          width: 20px;
          color: transparent;
        }
        .breadcrumbs a {
          cursor: pointer;
          text-decoration: none;
          font-weight: 600;
        }
          .breadcrumbs a:hover {
            text-decoration: underline;
          }


    .subheader {
      position: relative;
      font-family: "Ubuntu", "Open Sans", "Tahoma", "Arial", "Helvetica", sans-serif;
      font-size: 30px;
      font-weight: 400;
      line-height: 1.3;
      text-transform: uppercase;
      padding: 15px 0px 20px;
      text-align: center;
      z-index: 2;
    }
      .subheader span {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: 900;
        line-height: inherit;
        text-transform: uppercase;
      }
      h1.subheader {
        font-size: 32px;
      }


    .black-bg-container {
      background: #333;
      padding: 10px 0px 0px;
      margin: 20px 0px;
    }
      .black-bg-container .subheader {
        color: #fff;
      }


    .text-container {
      padding: 0px 0px 30px;
      position: relative;
      z-index: 1;
    }


    .quick-link-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-content: flex-start;
      align-items: center;
      background: #eaeaea;
      padding: 0px 10px;
      margin: 0px 0px 20px;
      position: relative;
      z-index: 1;
    }
      .quick-link-list > div {
        padding: 15px 10px;
      }
        .quick-link-list a {
          display: block;
          font-size: 14px;
          line-height: 1;
          text-decoration: none;
          text-transform: uppercase;
          border-bottom: 1px dashed #000;
          cursor: pointer;
          position: relative;
        }
          .quick-link-list a:hover,
          .quick-link-list a.active {
            border-bottom-color: transparent;
          }
          .quick-link-list a.active {
            font-weight: 600;
            cursor: default;
          }


    .tab-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: center;
      padding: 0px 10px 0px 0px;
      margin: -10px 0px 20px -10px;
      position: relative;
      z-index: 1;
    }
      .tab-list > div {
        padding: 10px 0px 0px 10px;
      }


    .incut {
      color: #3278be;
      font-weight: 600;
      line-height: 1.3;
      text-transform: uppercase;
      padding: 25px 25px 25px 115px;
      margin: 0px 0px 20px;
      border: 3px solid #3278be;
      position: relative;
    }
      .incut:before {
        content: "";
        background: url('/f/upload/incut-icon.svg') no-repeat 50% 50% transparent;
        background-size: contain;
        position: absolute;
        top: 20px;
        left: 25px;
        bottom: 20px;
        width: 65px;
      }


    .incut2 {
      padding: 0px 0px 20px;
      position: relative;
    }
      .incut2:before {
        content: "";
        background: url('/i/incut-bg.webp') no-repeat 50% 50%/cover transparent;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 20px;
      }
      .incut2:after {
        content: "";
        background: rgba(0,0,0,.6);
        mix-blend-mode: multiply;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 20px;
      }

      .incut2 > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        overflow: hidden;
      }
        .incut2 > div:before {
          content: "";
          display: block;
          background: #f6f6f6;
          height: 0px;
          width: 30%;
          padding-top: 15%;
          position: absolute;
          right: 0px;
          bottom: 20px;
          z-index: 1;
          transform: skewX(-40deg) translateX(80%);
        }

        .incut2 > div > div {
          width: 50%;
        }

        .incut2 > div > div:first-child {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
          .incut2 > div > div:first-child > div {
            color: #fff;
            font-size: 24px;
            width: 200%;
            min-width: 200%;
            padding: 30px 25px 30%;
            position: relative;
            z-index: 2;
          }
            .incut2 > div > div:first-child > div .subheader {
              font-size: 30px;
              font-weight: 700;
            }
              .incut2 > div > div:first-child > div .subheader:after {
                background: #fff;
              }


        .incut2 > div > div + div {
          font-size: 0px;
        }
          .incut2 > div > div + div:before {
            content: "";
            display: block;
            height: 0px;
            padding-top: 75%;
          }



    .advantages-block-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: stretch;
      align-content: flex-start;
      padding: 0px 0px 20px;
      margin: -12px 0px 0px -12px;
    }
      .advantages-block-container > div {
        width: 25%;
        padding: 12px 0px 0px 12px;
      }
        .advantages-block-container > div > div {
          width: 100%;
          height: 100%;
          border: 3px solid #e1e1e1;
          padding: 25px 20px 75px;
          position: relative;
        }
          .advantages-block-container > div > div:hover {
            background: #3278be;
            border-color: #3278be;
          }
          .advantage-item-image {
            padding: 0px 0px 20px;
          }
            .advantage-item-image img {
              height: 80px;
            }
              .advantage-item-image img + img,
              .advantages-block-container div:hover > .advantage-item-image img:first-child {
                display: none;
              }
                .advantages-block-container div:hover > .advantage-item-image img + img {
                  display: inline;
                }

          .advantage-item-name {
            color: #3278be;
            font-size: 22px;
            font-weight: 700;
            line-height: 1.2;
            min-height: 68px;
            text-transform: uppercase;
            padding: 0px 0px 15px;
          }

          .advantage-item-notice {
            font-size: 16px;
            padding: 0px 0px 20px;
          }
            .advantages-block-container div:hover > .advantage-item-name,
            .advantages-block-container div:hover > .advantage-item-notice {
              color: #fff;
            }

          .advantage-item-arrow {
            position: absolute;
            background: url('/i/arrows--blue.svg') no-repeat right center transparent;
            background-size: cover;
            left: 20px;
            bottom: 38px;
            width: 41px;
            height: 28px;
          }
            .advantages-block-container > div > div:hover .advantage-item-arrow {
              background-image: url('/i/arrows--white.svg');
            }


    .base-more-link {
      font-size: 0px;
    }
      .button-style .base-more-link {
        padding: 12px 28px;
      }
      .base-more-link a {
        line-height: 1.1;
        vertical-align: middle;
        cursor: pointer;
      }
        .base-more-link a:hover,
        .base-more-link.cw a,
        .base-more-link.cw_h a:hover,
        .button-style.button-style-white .base-more-link a {
          color: #fff;
        }
        .base-more-link.c0 a,
        .base-more-link.c0_h a:hover,
        .button-style:hover .base-more-link a {
          color: #000;
        }
        .base-more-link.cblue a,
        .base-more-link.cblue_h a:hover {
          color: #3278be;
        }
        .base-more-link.corange a,
        .base-more-link.corange_h a:hover {
          color: #f0964b;
        }
        .base-more-link a:after {
          content: "";
          background: none no-repeat right center transparent;
          background-size: cover;
          display: inline-block;
          vertical-align: middle;
          width: 26px;
          height: 18px;
          margin: 0px 0px 0px 10px;
        }
          .base-more-link a:after,
          .base-more-link.c0 a:after,
          .base-more-link.c0_h a:hover:after,
          .button-style:hover .base-more-link a:after,
          .button-style.button-style-white:hover .base-more-link a:after {
            background-image: url('/i/arrows--black.svg');
          }
          .base-more-link a:hover:after,
          .base-more-link.cw a:after,
          .base-more-link.cw_h a:hover:after,
          .button-style.button-style-white .base-more-link a:after {
            background-image: url('/i/arrows--white.svg');
          }
          .base-more-link.cblue a:after,
          .base-more-link.cblue_h a:hover:after {
            background-image: url('/i/arrows--blue.svg');
          }
          .base-more-link.corange a:after,
          .base-more-link.corange_h a:hover:after {
            background-image: url('/i/arrows--orange.svg');
          }


    .service-page-group-name {
      font-size: 25px;
      font-weight: 900;
      line-height: 1.1;
      text-transform: uppercase;
      padding-bottom: 20px;
    }
      .service-block-container + .service-page-group-name {
        padding-top: 15px;
      }
      .service-page-group-name + .service-block-container + .service-block-container {
        padding-top: 20px;
      }


    .gallery-block-container > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      padding: 0px 0px 20px;
      margin: -20px 0px 0px -20px;
      position: relative;
    }
      .gallery-block-container .gallery-item {
        padding: 20px 0px 0px 20px;
        position: relative;
      }
      .gallery-block-container .gallery-notice {
        padding: 0px 0px 20px;
        position: relative;
      }


    .list-view,
    .portfolio-items {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: stretch;

      padding: 0px 0px 20px;
      margin: -20px 0px 0px -20px;
      position: relative;
    }
      .list-view-item {
        width: 408px;
        padding: 20px 0px 0px 20px;
        position: relative;
      }
        .list-view-item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          width: 100%;
          height: 100%;
          border: 2px solid #3278be;
          position: relative;
        }
          .list-view-item-image {
            background: #eaeaea;
            font-size: 0px;
            position: relative;
            order: 2;
            z-index: 1;
          }
            .list-view-item-image a,
            .list-view-item-image span {
              display: block;
              height: 250px;
              font-size: 0px;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: cover;
            }
              .list-view-item-image a {
                cursor: pointer;
              }

          .list-view-item-text {
            padding: 20px 20px 25px;
            position: relative;
            order: 3;
            z-index: 2;
          }

          .list-view-item-name {
            background: #3278be;
            font-size: 0px;
            position: relative;
            order: 1;
          }
            .list-view-item-text .list-view-item-name {
              background: transparent;
            }
            .list-view-item-name a,
            .list-view-item-name span {
              display: block;
              color: #fff;
              text-align: center;
              font-size: 19px;
              font-weight: 700;
              line-height: 1.3;
              padding: 12px 8px 13px;
              text-transform: uppercase;
              text-decoration: none;
            }
              .list-view-item-name a {
                cursor: pointer;
              }
              .list-view-item-text .list-view-item-name a,
              .list-view-item-text .list-view-item-name span {
                color: #000;
                display: inline;
                text-align: left;
                padding: 0px;
              }
              .list-view-item-text .list-view-item-name a,
              .list-view-item-image + .list-view-item-text .list-view-item-name a {
                text-decoration: underline;
              }
                .list-view-item-text .list-view-item-name a:hover,
                .list-view-item-image:hover + .list-view-item-text .list-view-item-name a {
                  text-decoration: none;
                }

          .list-view-item-name + .list-view-item-notice {
            padding: 15px 0px 0px;
          }
            .list-view-item-notice * {
              font-size: 14px;
              text-align: left;
            }
            .list-view-item-notice *:last-child {
              margin-bottom: 0px;
            }


    .product-block-container .list-view-item {
      width: 306px;
    }
      /*.product-block-container .list-view-item-wrapper {
        border-color: #eaeaea;
      }
        .product-block-container .list-view-item-name {
          background: #eaeaea;
        }*/
          /*.product-block-container .list-view-item-name a,
          .product-block-container .list-view-item-name span {
            color: #000;
          }*/

        .product-block-container .list-view-item-image a {
          height: 190px;
        }

        .product-view-item-price {
          font-size: 24px;
          font-weight: 700;
          line-height: 1.1;
          text-align: center;
          vertical-align: baseline;
        }
          .product-view-item-price span {
            font-size: 18px;
            font-weight: 400;
            line-height: inherit;
            vertical-align: baseline;
          }

        .product-view-item-params * {
          font-size: 15px;
        }
        .product-view-item-params > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
          .product-view-item-params > div + div {
            padding: 2px 0px 0px;
          }
          .product-view-item-params > div > * + * {
            padding: 0px 0px 0px 20px;
          }

        .product-view-item-price + .product-view-item-params,
        .product-view-item-price + .list-view-item-notice,
        .product-view-item-params + .list-view-item-notice {
          padding: 10px 0px 0px;
        }


    .portfolio-block-container .list-view {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      margin: 0px;
    }
      .portfolio-block-container .list-view-item-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,.3);
        padding: 0px;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
      }
        .portfolio-block-container .portfolio-item-bg-link {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          cursor: pointer;
        }
        .portfolio-block-container .portfolio-item-name {
          margin: 4px 0px 8px;
        }
          .portfolio-block-container .portfolio-item-name a {
            display: block;
            color: #fff;
            font-size: 28px;
            font-weight: 900;
            line-height: 1.1;
            text-align: center;
            text-transform: uppercase;
            padding: 0px 15px;
            cursor: pointer;
          }

        .portfolio-block-container .portfolio-item-address,
        .portfolio-block-container .portfolio-item-info-block > div {
          font-size: 0px;
          text-align: center;
          padding: 0px 10px 10px;
        }
        .portfolio-item-info-block {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-content: flex-start;
          align-items: flex-start;
        }
          .portfolio-block-container .portfolio-item-address img,
          .portfolio-block-container .portfolio-item-info-block > div img {
            height: 16px;
            margin-right: 8px;
            vertical-align: middle;
          }
          .portfolio-block-container .portfolio-item-address span,
          .portfolio-block-container .portfolio-item-info-block > div span {
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            line-height: 1.1;
            text-align: center;
            vertical-align: middle;
          }

      .team-block-container .list-view-item {
        width: 25%;
      }
        .team-block-container .list-view-item-image a,
        .team-block-container .list-view-item-image span {
          height: 240px;
        }

        .team-block-container .list-view-item-name a,
        .team-block-container .list-view-item-name span {
          font-size: 19px;
          text-transform: none;
        }


    .discount-block-container .list-view {
      flex-flow: column nowrap;
      margin: 0px;
    }
      .discount-block-container .list-view-item {
        width: 100%;
        padding: 0px;
      }
        .discount-block-container .list-view-item + .list-view-item {
          margin-top: -3px;
        }
        .discount-block-container .list-view-item-wrapper {
          flex-direction: row;
        }
          .discount-block-container .list-view-item-image a,
          .discount-block-container .list-view-item-image span {
            width: 420px;
            height: 100%;
            min-height: 210px;
          }


    .discount-page-image {
      height: 0px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: transparent;
      background-size: cover;
      padding: 17% 0px 0px;
      margin: 0px 0px 20px;
    }


    .price-table {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: 0px 0px 20px;
      position: relative;
    }
      .price-table-counter {
        counter-reset: price-table-group-counter;
      }
      .price-table > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
      }
      .price-table-group {
        padding-bottom: 13px;
        justify-content: center !important;
      }
        .price-table-counter .price-table-group {
          counter-reset: price-table-subheader-counter;
        }
        .price-table > div + .price-table-group {
          margin-top: 35px;
        }
        .price-table-group span {
          font-size: 24px;
          /*font-weight: 700;
          text-transform: uppercase;*/
        }
          .price-table-counter .price-table-group span:before {
            content: counter(price-table-group-counter) ". ";
            counter-increment: price-table-group-counter;
            font-weight: 300;
          }

      .price-table-head {
        background: /*#3278be*/ #8cb4ce;
      }

      .price-table-subheader {
        background: #e1e1e1;
        position: relative;
        z-index: 1;
      }
        .price-table-row + .price-table-subheader {
          margin-top: -1px;
        }
      .price-table-row {
        background: rgba(2,100,165,.06);
      }
        .price-table-row:nth-child(2n+1) {
          background: rgba(2,100,165,.02);
        }
        .price-table-head > *,
        .price-table-subheader > *,
        .price-table-row > * {
          padding: 3px 18px 2px;
        }
        .price-name,
        .price-unit,
        .price-price {
          flex-basis: 100%;
          flex-shrink: 2;
          font-size: 15px;
        }
        .price-name {
          padding-left: 35px;
        }
        .price-price {
          flex-shrink: 3;
        }
        .price-unit,
        .price-unit + .price-price {
          flex-shrink: 4;
        }
        .price-unit,
        .price-price {
          text-align: center;
          border-left: 2px solid #e1e1e1;
        }
          .price-table-head > * {
            /*color: #fff;*/
            font-size: 18px;
            padding-top: 5px;
            padding-bottom: 5px;
          }
          .price-table-head .price-price,
          .price-table-head .price-unit {
            border-left-color: /*#3278be*/ #8cb4ce;
          }
          .price-table-subheader .price-name {
            font-weight: 600;
            text-transform: uppercase;
            padding: 5px 18px;
            text-align: center;
          }
            .price-table-counter .price-table-subheader .price-name:before {
              content: counter(price-table-group-counter) "." counter(price-table-subheader-counter) ". ";
              counter-increment: price-table-subheader-counter;
              font-weight: 300;
            }

          .price-table-row > * {
            border-bottom: 2px solid #e1e1e1;
          }

    .price-table-more-link {
      text-align: center;
      padding: 0px 0px 20px;
    }
      .price-table-more-link a > * {
        padding-left: 3.75em;
        background-image: url('/i/price-more-button-icon--orange.svg');
        background-repeat: no-repeat;
        background-position: 1.5em center;
        background-size: 22px;
      }
        .price-table-more-link a:hover > * {
          background-image: url('/i/price-more-button-icon--white.svg');
        }


    /*.main-gallery-block-container {
      padding: 0px 0px 20px;
    }
      .main-gallery-rotator {
        overflow: hidden;
      }
        .main-gallery-rotator .slick-list {
          width: 680px;
          margin: 0px auto;
          overflow: visible !important;
        }
          .main-gallery-rotator .slick-track {
            display: block !important;
            margin: 0px 0px 0px -85px;
            height: 500px;
          }
            .main-gallery-image-container {
              display: inline-block !important;
              width: 680px !important;
              height: 500px !important;
              padding: 50px 0px;
              position: relative;

              -webkit-transition: width 0.5s ease 0s, padding 0.5s ease 0s;
              -moz-transition: width 0.5s ease 0s, padding 0.5s ease 0s;
              -o-transition: width 0.5s ease 0s, padding 0.5s ease 0s;
              transition: width 0.5s ease 0s, padding 0.5s ease 0s;
            }
              .main-gallery-image-container:before {
                content: "";
                position: absolute;
                top: 50px;
                left: 0px;
                right: 0px;
                bottom: 50px;
                box-shadow: 0px 0px 70px -20px transparent;

                -webkit-transition: top 0.5s ease 0s, bottom 0.5s ease 0s, box-shadow 0.5s ease 0s;
                -moz-transition: top 0.5s ease 0s, bottom 0.5s ease 0s, box-shadow 0.5s ease 0s;
                -o-transition: top 0.5s ease 0s, bottom 0.5s ease 0s, box-shadow 0.5s ease 0s;
                transition: top 0.5s ease 0s, bottom 0.5s ease 0s, box-shadow 0.5s ease 0s;
              }
              .main-gallery-image-container.slick-active {
                width: 850px !important;
                padding: 0px;
                z-index: 1;
              }
                .main-gallery-image-container.slick-active:before {
                  top: 45px;
                  bottom: 10px;
                  box-shadow: 0px 0px 60px -2px #000;
                }
              .main-gallery-image-container a,
              .main-gallery-image-container span {
                display: block;
                position: relative;
                z-index: 1;
              }
                .main-gallery-image-container a:after,
                .main-gallery-image-container span:after {
                  content: "";
                  background: #333;
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;

                  opacity: 0.7;

                  -webkit-transition: opacity 0.5s ease 0s;
                  -moz-transition: opacity 0.5s ease 0s;
                  -o-transition: opacity 0.5s ease 0s;
                  transition: opacity 0.5s ease 0s;
                }
                  .main-gallery-image-container.slick-active a:after,
                  .main-gallery-image-container.slick-active span:after {
                    opacity: 0;
                  }
                .main-gallery-image-container a:before {
                  opacity: 0;
                }
                  .main-gallery-image-container.slick-active a:before {
                    opacity: 0.7;
                  }
                .main-gallery-image-container img {
                  width: 100%;
                }

        .main-gallery-controls {
          width: 850px;
          height: 0px;
          margin: 0px auto;
          position: relative;
          z-index: 1;
        }
          .main-gallery-controls .slick-prev,
          .main-gallery-controls .slick-next {
            position: absolute;
            top: 50px;
            width: 9999px;
            height: 400px;
            cursor: pointer;
          }
            .main-gallery-controls .slick-prev {
              right: 100%;
            }
            .main-gallery-controls .slick-next {
              left: 100%;
            }
            .main-gallery-controls .button-style {
              position: absolute;
              top: 175px;
            }
              .main-gallery-controls .slick-prev .button-style {
                right: 2px;
              }
              .main-gallery-controls .slick-next .button-style {
                left: 2px;
              }
              .main-gallery-controls .button-style:before {
                background: rgba(51,51,51,.6);
                right: 0px;
                padding-right: 0px;
                width: auto;
                filter: blur(5px);
              }
              .main-gallery-controls .button-style:after {
                display: none;
              }
              .main-gallery-controls .button-style > div {
                font-size: 0px;
                height: 50px;
                width: 60px;
                padding: 0px;
                margin: 0px;
                position: relative;
              }
                .main-gallery-controls .button-style > div:before {
                  content: "";
                  background: url('/i/arrows--white.svg') no-repeat transparent;
                  background-size: cover;
                  display: block;
                  width: 41px;
                  height: 28px;
                  margin: 11px auto;
                }
                  .main-gallery-controls .slick-prev .button-style > div:before {
                    background-position: left center;
                  }
                  .main-gallery-controls .slick-next .button-style > div:before {
                    background-position: right center;
                  }
                  .main-gallery-controls .slick-prev:hover .button-style > div:before,
                  .main-gallery-controls .slick-next:hover .button-style > div:before {
                    background-image: url('/i/arrows--orange.svg');
                  }

        .main-gallery-dots {
          width: 1204px;
          height: 0px;
          margin: 0px auto;
          position: relative;
        }
          .main-gallery-rotator.slick-dotted .main-gallery-dots {
            height: 50px;
          }
          .main-gallery-dots > div {
            float: right;
            width: 720px;
            height: 0px;
            padding-left: 35px;
            position: relative;
          }
            .main-gallery-dots > div > div {
              display: inline-block;
              min-width: 165px;
            }
              .main-gallery-dots ul {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 50px;
              }
                .main-gallery-dots li {
                  width: 15px;
                  height: 15px;
                  border: 2px solid #b7b7b7;
                  !*border-radius: 50%;*!
                  cursor: pointer;
                }
                  .main-gallery-dots li:hover {
                    border-color: #fff;
                  }
                  .main-gallery-dots li + li {
                    margin-left: 15px;
                  }
                  .main-gallery-dots li.slick-active {
                    background: #3278be;
                    border-color: #3278be !important;
                  }

        .main-gallery-notice-container {
          width: 850px;
          height: 50px;
          margin: 0px auto;
          position: relative;
          z-index: 1;
        }
          .main-gallery-rotator.slick-dotted .main-gallery-notice-container {
            height: 0px;
          }
          .main-gallery-notice-container > div {
            position: absolute;
            background: #3278be;
            left: 0px;
            bottom: 0px;
            width: 307px;
            max-height: 250px;
            border: 20px solid #3278be;
            overflow: hidden;
          }
            .main-gallery-notice-container > div * {
              color: #fff;
            }
            .main-gallery-notice-container > div .base-more-link a:after {
              background-image: url('/i/arrows--white.svg');
            }*/


    .portfolio-info-block-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 0px 20px;
      margin: 0px 0px 0px -25px;
    }
      .portfolio-info-block-container > div {
        padding-left: 25px;
      }
        .portfolio-info-block-container > div img {
          height: 18px;
          margin-right: 8px;
          vertical-align: middle;
        }
        .portfolio-info-block-container > div span {
          font-size: 17px;
          vertical-align: middle;
        }


    .project-page-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px 0px 20px;
    }
      .project-page-gallery-container {
        min-width: 614px;
        max-width: 614px;
        position: relative;
      }
        .project-page-gallery {
          float: right;
          font-size: 0px;
          width: 461px;
          height: 302px;
          margin: 0px 0px 0px -2px;
          border: 2px solid #3278be;
          position: relative;
          overflow: hidden;
          z-index: 1;
        }
          .project-page-gallery .slick-slide > div {
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-color: transparent;
            background-size: cover;
            width: 457px;
            height: 298px;
            position: relative;
            overflow: hidden;
          }
            .project-page-gallery .slick-slide > div a {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
            }

        .project-page-gallery-pager > ul {
          font-size: 0px;
          margin: 2px 0px 0px 2px;
          text-align: left;
          position: relative;
        }
          .project-page-gallery-pager li {
            display: inline-block;
            font-size: 0px;
            width: 155px;
            height: 102px;
            margin: -2px 0px 0px -2px;
            border: 2px solid transparent;
            position: relative;
          }
            .project-page-gallery-pager li.slick-active {
              border-color: #3278be;
              z-index: 1;
            }
            .project-page-gallery-pager div {
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-color: transparent;
              background-size: cover;
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              cursor: pointer;
              opacity: 0.6;
            }
              .project-page-gallery-pager div:hover,
              .project-page-gallery-pager li.slick-active div {
                opacity: 1;
              }

      .project-page-info-wrapper {
        width: 100%;
      }
        .project-page-gallery-container + .project-page-info-wrapper {
          padding: 0px 0px 0px 25px;
        }

        .project-page-notice + .project-page-params,
        .project-page-notice + .project-page-price-wrapper,
        .project-page-params + .project-page-price-wrapper,
        .project-page-buttons {
          margin-top: 20px;
        }

        .project-page-params {
          width: 80%;
        }
          .project-page-params > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            background: rgba(2,100,165,.02);
            padding: 3px 0px;
            border-top: 2px solid #e1e1e1;
            border-bottom: 2px solid #e1e1e1;
          }
            .project-page-params > div:nth-child(2n+1) {
              background: rgba(2,100,165,.06);
            }
            .project-page-params > div + div {
              margin-top: -2px;
            }
            .project-page-params > div > * {
              padding: 0px 10px;
              text-align: right;
            }
              .project-page-params > div * + * {
                padding-left: 20px;
              }

        .project-page-price-old {
          font-size: 18px;
          font-weight: 300;
          padding: 0px 0px 4px;
          vertical-align: baseline;
        }
          .project-page-price-old * {
            font-size: 22px;
            font-weight: 300;
            vertical-align: baseline;
          }

        .project-page-price {
          color: #f0964b;
          font-size: 24px;
          /*font-weight: 300;*/
          vertical-align: baseline;
        }
          .project-page-price * {
            color: #f0964b;
            font-size: 28px;
            font-weight: 600;
            vertical-align: baseline;
          }


    .project-fast-order-container {
      display: inline-block;
      background: #fff;
      max-width: 1204px;
      padding: 10px 0px 0px;
      border: 7px solid #3278be;
    }
      .project-fast-order-form-container {
        width: 740px;
        padding: 0px 20px 25px;
      }
        .project-fast-order-name {
          margin-bottom: 20px;
        }
          .project-fast-order-name .form-label {
            margin-top: 0px;
          }
        .fast-order-form .form-buttons {
          margin-left: 165px;
        }


/* Contact */
.contact-map-block-container {
  /*font-size: 0px;*/
  padding: 0px 0px 50px;
  margin: 0px 0px 20px;
  position: relative;
}
  .contact-map-placeholder {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 50px;
    z-index: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
    .contact-map-placeholder:before {
      content: "Загрузка карты...";
      margin: 0px 0px 0px 420px;
    }
  .contact-map-container {
    height: 600px;
    border: 7px solid #3278be;
    border-left: none;
    border-right: none;
    position: relative;
    z-index: 2;
  }
  .contact-info-container {
    height: 0px;
    overflow: visible;
    position: relative;
    z-index: 3;
  }
    .contact-info-content {
      position: absolute;
      left: 0px;
      bottom: -50px;
      width: 410px;

      background: #fff;
      border: 7px solid #3278be;
      padding: 25px 20px 30px 30px;
    }
      .contact-info-content > div + div {
        margin-top: 20px;
      }
        .contact-info-label span {
          display: block;
          color: #3278be;
          font-size: 18px;
          font-weight: 900;
          line-height: 1.3;
          text-transform: uppercase;
          margin: 0px 0px 5px;
        }
        .contact-info-worktime,
        .contact-info-worktime * {
          font-size: 14px;
        }
        .contact-info-phones a {
          font-size: 22px;
          font-weight: 600;
          line-height: 1.3;
          cursor: default;
        }
        .contact-info-emails a {
          font-weight: 600;
          line-height: 1.1;
          text-decoration: underline;
          cursor: pointer;
        }
          .contact-info-emails a:hover {
            text-decoration: none;
          }


/* Footer */
.footer-callback-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  color: #fff;
  background: url('/i/incut-bg.webp') no-repeat 50% 50%/cover #f0f0f0;
  text-align: center;
  min-height: 300px;
  margin: 20px 0px 25px;
  position: relative;
}
  .footer-callback-container:before {
    content: "";
    background: rgba(0,0,0,.6);
    mix-blend-mode: multiply;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .footer-callback-container .callback-order-container {
    display: block;
    background: none;
    border: none;
    max-width: 900px;
    margin: 0px auto;
    padding: 50px 20px;
  }
    .footer-callback-container .callback-order-container > div {
      background: none;
      padding: 0px;
      margin: 0px;
    }
      .footer-callback-container .subheader {
        color: #fff;
        font-size: 30px;
        font-weight: 600;
        padding: 0px 0px 20px;
        margin: 0px;
        text-align: center;
      }
        .footer-callback-container .subheader:after {
          display: none;
        }

      .footer-callback-container .form-block-notice {
        font-size: 20px;
      }


      .footer-callback-container .callback-order-form-container,
      .footer-callback-container .callback-order-form-wrapper {
        display: block;
        width: auto;
        padding: 0px;
      }
          .footer-callback-container form {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            margin: -10px 0px 0px -10px;
          }
            .footer-callback-container .form-row {
              flex: 5 1 auto;
            }
            .footer-callback-container .form-row,
            .footer-callback-container .form-button,
            .footer-callback-container #form-success-message {
              padding: 10px 0px 0px 10px;
              margin: 0px;
            }
              .footer-callback-container .form-label {
                display: none;
              }

            .footer-callback-container .callback-form .form-buttons {
              flex: 1 1 auto;
              margin: 0px;
            }
              .footer-callback-container .form-button,
              .footer-callback-container .button-style {
                display: block;
                width: 100%;
              }
                .footer-callback-container .button-style > span {
                  font-size: 0.9rem;
                }

            .footer-callback-container #form-success-message {
              text-align: center;
            }


.callback-order-container {
  display: inline-block;
  background: #fff;
  max-width: 1204px;
  padding: 10px 0px 0px;
  border: 7px solid #3278be;
}
  .callback-order-form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20px;
  }
    .callback-order-form-wrapper {
      width: 700px;
      padding-bottom: 25px;
    }
      .callback-form .form-row-content-link,
      .callback-form .form-row-content {
        overflow: hidden;

        -webkit-transition: max-height 0.3s ease 0s, margin-top 0.3s ease 0s, opacity 0.3s ease 0s;
        -moz-transition: max-height 0.3s ease 0s, margin-top 0.3s ease 0s, opacity 0.3s ease 0s;
        -o-transition: max-height 0.3s ease 0s, margin-top 0.3s ease 0s, opacity 0.3s ease 0s;
        transition: max-height 0.3s ease 0s, margin-top 0.3s ease 0s, opacity 0.3s ease 0s;
      }
        .callback-form .form-row-content-link,
        .callback-form.callback-form-with-content .form-row-content {
          max-height: 90px;
          margin-top: 20px;
          opacity: 1;
        }
        .callback-form.callback-form-with-content .form-row-content-link,
        .callback-form .form-row-content {
          max-height: 0px;
          margin-top: 0px;
          opacity: 0;
        }
          .callback-form .form-row-content-link span {
            color: #3278be;
            font-size: 15px;
            font-weight: 600;
            line-height: 1;
            border-bottom: 1px dashed #3278be;
            cursor: pointer;
          }
            .callback-form .form-row-content-link span:hover {
              border-bottom-color: transparent;
            }

      .callback-form .form-buttons {
        margin-left: 165px;
      }


.footer-menu-container {
  background: #3278be;
}
  .footer-menu-container ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
      .footer-menu-container ul li a,
      .footer-menu-container ul li span {
        display: block;
        color: #fff;
        position: relative;
        font-size: 15px;
        font-weight: 600;
        padding: 12px 18px;
        text-transform: uppercase;
        text-decoration: none;
        white-space: nowrap;
      }
      .footer-menu-container ul li span {
        cursor: default;
      }
      .footer-menu-container ul li a {
        cursor: pointer;
      }
        .footer-menu-container ul li.active a,
        .footer-menu-container ul li a:hover {
          background: #333;
        }
        .footer-menu-container ul li.active a {
          cursor: default;
          font-weight: 700;
        }

.footer {
  background: #333;
  padding: 30px 0px;
  margin: 0px;
  position: relative;
  z-index: 1;
}
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
    .footer-phones-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background: url('/i/callback-button-icon--orange.svg') no-repeat 0% 1px transparent;
      background-size: auto 26px;
      padding-left: 36px;
    }
      .footer-phones,
      .footer-worktime {
        padding-bottom: 10px;
      }
        .footer-phones div {
          font-size: 0px;
        }
          .footer-phones a {
            color: #fff;
            font-size: 28px;
            font-weight: 700;
            line-height: 1;
            text-decoration: none;
            cursor: default;
          }

        .footer-worktime * {
          color: #fff;
          font-size: 16px;
          line-height: 1.3;
        }
          .footer-worktime * > span {
            display: inline-block;
            font-weight: 700;
            padding: 0px 6px;
            margin: 0px 0px 0px 3px;
            position: relative;
          }
            .footer-worktime * > span:before {
              content: "";
              position: absolute;
              background: #fff;
              top: 3px;
              left: 0px;
              bottom: 2px;
              width: 2px;
            }

        .footer-contact-callback-link {
          font-size: 0px;
        }
          .footer-contact-callback-link a > * {
            color: #f0964b;
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
            border-bottom: 1px dashed #f0964b;
            cursor: pointer;
          }
            .footer-contact-callback-link a:hover > * {
              border-bottom-color: transparent;
            }

    .footer-info-menu-container ul {
      padding-left: 20px;
    }
      .footer-info-menu-container li,
      .footer-info-menu-container li div {
        font-size: 0px;
      }
        .footer-info-menu-container li a {
          color: #fff;
          font-size: 1rem;
          line-height: 1.3;
          text-decoration: underline;
          cursor: pointer;
        }
        .footer-info-menu-container li a:hover,
        .footer-info-menu-container li.active a {
          color: #f0964b;
          text-decoration: none;
        }
        .footer-info-menu-container li.active a {
          font-weight: 600;
          cursor: default;
        }

    .footer-address-container {
      background: url('/i/footer-address-icon.svg') no-repeat 0% 3px transparent;
      background-size: auto 25px;
      padding-left: 36px;
    }
      .footer-address * {
        color: #fff;
        font-size: 17px;
        line-height: 1.3;
      }


  .footer-copyrights {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
    .footer-copyright + .footer-copyright {
      padding-top: 10px;
    }
    .footer-copyright,
    .footer-copyright * {
      color: #fff;
      font-size: 15px;
    }


  /* Other */
  .grecaptcha-badge {
    z-index: 9;
  }